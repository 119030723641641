/* eslint-disable */
import React, { Fragment, useState } from 'react';
import Helmet from 'react-helmet';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import axios from 'axios';
import * as Yup from 'yup';
import {
  Pane,
  TextInput,
  Heading,
  Spinner,
  Text,
  Textarea,
  Button,
  FormField,
} from 'evergreen-ui';
import Layout from 'components/layout';
import LauvLogo from '../../static/Logo.png';

const API = 'https://5we2ah62dl.execute-api.us-east-2.amazonaws.com/Production';

const ModernLoneliness = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  return (
    <Fragment>
      <Helmet title="Lauv - Modern Loneliness" defer={false} />

      <Container>
        <div
          style={{
            textAlign: 'center',
            marginBottom: '20px',
          }}
        >
          <img src={LauvLogo} width="200px" alt="Lauv" />
        </div>
        {error && (
          <div
            style={{
              width: '400px',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            <Heading size={800} marginTop="default" marginBottom="7px">
              Error - Please Try Again Later
            </Heading>
            <Heading size={300} marginTop="default" marginBottom="7px">
              <a href="/">back to lauvsongs.com</a>
            </Heading>
          </div>
        )}

        {success ? (
          <div
            style={{
              width: '400px',
              margin: 'auto',
              textAlign: 'center',
            }}
          >
            <Heading size={800} marginTop="default" marginBottom="7px">
              Thank You!
            </Heading>
            <Heading size={300} marginTop="default" marginBottom="7px">
              <a href="/">back to lauvsongs.com</a>
            </Heading>
          </div>
        ) : (
          <Formik
            validationSchema={Schema}
            initialValues={{
              fName: '',
              lName: '',
              email: '',
              city: '',
              social: '',
              reconnect: '',
            }}
            onSubmit={async (values, actions) => {
              try {
                const req = await axios.post(API, values);
                setSuccess(true);
              } catch (e) {
                setError(true);
              } finally {
                actions.setSubmitting(false);
              }
            }}
          >
            {({ values, errors, touched, handleChange, isSubmitting }) => (
              <Form>
                <Pane>
                  <Field name="fName">
                    {({ field, form: { touched, errors }, meta }) => (
                      <FormField
                        label="First Name"
                        marginBottom="15px"
                        validationMessage={
                          meta.touched && meta.error ? true : false
                        }
                        isRequired
                      >
                        <TextInput
                          type="text"
                          style={{ width: '100%' }}
                          {...field}
                        />
                      </FormField>
                    )}
                  </Field>
                  <Field name="lName">
                    {({ field, form: { touched, errors }, meta }) => (
                      <FormField
                        label="Last Name"
                        marginBottom="15px"
                        validationMessage={
                          meta.touched && meta.error ? true : false
                        }
                        isRequired
                      >
                        <TextInput
                          type="text"
                          style={{ width: '100%' }}
                          {...field}
                        />
                      </FormField>
                    )}
                  </Field>
                  <Field name="email">
                    {({ field, form: { touched, errors }, meta }) => (
                      <FormField
                        label="Email"
                        marginBottom="15px"
                        validationMessage={
                          meta.touched && meta.error ? true : false
                        }
                        isRequired
                      >
                        <TextInput
                          type="email"
                          style={{ width: '100%' }}
                          {...field}
                        />
                      </FormField>
                    )}
                  </Field>
                  <Field name="city">
                    {({ field, form: { touched, errors }, meta }) => (
                      <FormField
                        label="City"
                        marginBottom="15px"
                        validationMessage={
                          meta.touched && meta.error ? true : false
                        }
                        isRequired
                      >
                        <TextInput
                          type="text"
                          style={{ width: '100%' }}
                          {...field}
                        />
                      </FormField>
                    )}
                  </Field>
                  <Field name="social">
                    {({ field, form: { touched, errors }, meta }) => (
                      <FormField
                        label="Social Media Handle"
                        marginBottom="15px"
                        validationMessage={
                          meta.touched && meta.error ? true : false
                        }
                        isRequired
                      >
                        <TextInput
                          type="text"
                          style={{ width: '100%' }}
                          {...field}
                        />
                      </FormField>
                    )}
                  </Field>
                  <Field name="reconnect">
                    {({ field, form: { touched, errors }, meta }) => (
                      <FormField
                        label="Who would you want to reconnect with and why?"
                        marginBottom="15px"
                        validationMessage={
                          meta.touched && meta.error ? true : false
                        }
                        isRequired
                      >
                        <Textarea style={{ width: '100%' }} {...field} />
                      </FormField>
                    )}
                  </Field>
                  <div>
                    {isSubmitting ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Spinner size={16} style={{ marginRight: '6px' }} />{' '}
                        <Text size={400}>Submitting...</Text>
                      </div>
                    ) : (
                      <Button type="submit" marginTop={16}>
                        Submit
                      </Button>
                    )}
                  </div>
                </Pane>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </Fragment>
  );
};

export default ModernLoneliness;

const Container = styled.div`
  flex-direction: column;
  padding: 0 16px;
  max-width: 340px;
  width: 100%;
  margin: 30px auto;
`;

const Schema = Yup.object().shape({
  fName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  social: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  city: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
});
